import type {
	JSONArray,
	JSONData,
	JSONObject,
	JSONPartial,
} from '@horfix/horfix-common/types/json'
import type {
	ApiCallImpl,
	ApiCallUrlImpl,
	ApiCallParams,
} from '@horfix/horfix-common/types/web'

import { getPreference } from '@/data/prefs'

import { eq } from '@horfix/horfix-common/util/eq'
import { idAssert } from '@horfix/horfix-common/util/id'
import { batch } from '@horfix/horfix-common/util/iter'
import {
	parallelRunner,
	SequentialRunner,
} from '@horfix/horfix-common/util/runner'

import { getCurrentBranch } from './getCurrentBranch'

export class APIError extends Error {
	message: string
	code: number
	extra?: JSONObject

	url: string
	res?: Response

	constructor(
		url: string,
		message: string,
		code: number,
		extra?: JSONObject,
		res?: Response,
	) {
		super(message)
		this.message = message
		this.code = code
		this.extra = extra

		this.url = url
		this.res = res
	}

	static isAPIError(err: any): err is APIError {
		return err instanceof APIError
	}
	static isCode<N extends number>(
		err: any,
		code: N,
	): err is APIError & { code: N } {
		return APIError.isAPIError(err) && err.code === code
	}
	static isMessage<M extends string>(
		err: any,
		msg: M,
	): err is APIError & { message: M } {
		return APIError.isAPIError(err) && err.message === msg
	}
}

const apiCallUrl: ApiCallUrlImpl = function apiCallUrl(
	url: string,
	params?: { [k: string]: JSONData | undefined },
): string {
	if (params) {
		const alreadyReplaced = new Set()
		url = url.replaceAll(
			/:(!?)([a-zA-Z0-9_]+)(\*?)/g,
			(_, unescape, param, star) => {
				alreadyReplaced.add(param)

				let value = params[param]
				if (typeof value == 'number') value = '' + value
				if (typeof value == 'boolean') value = value ? '1' : '0'

				if (typeof value == 'object')
					throw new Error(`Param ${param} is object in path part`)
				if (value == null)
					throw new Error(`Param ${param} is missing in path part`)

				if (unescape) return value
				if (star)
					return value.split('/').map(encodeURIComponent).join('/')
				return encodeURIComponent(value)
			},
		)

		const qs = Object.keys(params)
			.filter(k => params[k] != null && !alreadyReplaced.has(k))
			.map(k => {
				let v = params[k]
				if (v == null) throw new Error('unreachable!')
				if (typeof v == 'object') v = JSON.stringify(v)
				if (typeof v == 'number') v = '' + v
				if (typeof v == 'boolean') v = v ? '1' : '0'

				return [k, v].map(encodeURIComponent).join('=')
			})
			.join('&')
		if (qs) url += '?' + qs
	}

	if (!url.startsWith('/')) url = '/' + url
	url = `/api${url}`

	url = url.replaceAll(/\/+/g, '/')

	return url
} as ApiCallUrlImpl

/**
 * Makes an API call
 * Checks that the response is `ok` or throws
 * Automatically handles auth and project unless specified, if the globals are set
 */
export const apiCall: ApiCallImpl & {
	augment: typeof augment
	url: typeof apiCallUrl
	APIError: typeof APIError
} = Object.assign(
	async function apiCall(
		/**
		 * The API endpoint we're reaching
		 */
		url: string,
		/**
		 * What we're doing to the endpoint
		 */
		{
			body,
			method,
			params,
			headers: extraHeaders,
			raw = false,
			timeout = 0,
			bulk = false,
			runner = bulk ? new SequentialRunner() : parallelRunner,
		}: ApiCallParams = {},
	): Promise<JSONPartial | Blob | Response> {
		const headers: any = {}
		let outBody: FormData | Blob | ReadableStream | string | undefined =
			undefined
		const currentBranch = getCurrentBranch()

		if (body !== undefined) {
			if (body instanceof FormData) {
				// application/form-data
				// but the browser sets the type *and* a boundary
				// and we can't do that ourselves
				outBody = body
			} else if (body instanceof Blob) {
				headers['content-type'] =
					body.type || 'application/octet-stream'
				headers['content-length'] = body.size
				outBody = body
			} else if (body instanceof ReadableStream) {
				headers['content-type'] = 'application/octet-stream'
				outBody = body
			} else {
				headers['content-type'] = 'application/json'
				try {
					outBody = JSON.stringify(body)
				} catch (e) {
					if (typeof body == 'object' && !Array.isArray(body)) {
						idAssert<{ [k: string]: JSONData | undefined }>(body)
						for (const [k, v] of Object.entries(body)) {
							if (v === undefined) delete body[k]
						}
						outBody = JSON.stringify(body)
					} else {
						throw e
					}
				}
				headers['content-length'] = outBody.length
			}
		}
		if (extraHeaders) {
			for (const k in extraHeaders) headers[k] = extraHeaders[k]
		}
		headers['X-CSRF'] = '1'
		if (getPreference('hideAdmin') && !url.startsWith('/api/auth/')) {
			headers['X-Hide-Admin'] = '1'
		}
		if (currentBranch) headers['X-Branch'] = currentBranch

		if (bulk) {
			if (raw) throw new TypeError('Cannot use raw bulk api call')
			if (!Array.isArray(params?.keys))
				throw new TypeError('Cannot use bulk api call without keys')
			if (typeof bulk == 'number' && (bulk < 1 || (bulk | 0) !== bulk))
				throw new TypeError(
					'Cannot use bulk api call with a nonpositve integer bulk size, gave ' +
						bulk,
				)
			if (typeof bulk == 'boolean') bulk = 100
			if (!(params.keys as Array<JSONData>).length)
				return apiCall(url, {
					body,
					method,
					headers,
					raw: false,
					timeout,
					bulk: false,
					runner,
					params: { ...params },
				})

			const responses: (JSONPartial | Blob | null)[] = (await Promise.all(
				[...batch(params.keys as Array<JSONData>, bulk)].map(
					async keys => {
						return apiCall(url, {
							body,
							method,
							headers,
							raw: false,
							timeout,
							bulk: false,
							runner,
							params: { ...params, keys },
						})
					},
				),
			)) as any
			const result: JSONPartial = {}
			for (const response of responses) {
				if (response instanceof Blob) {
					console.error('Blob part in bulk apiCall', response)
					throw new APIError(url, 'bulk_part_blob', 0)
				}
				if (typeof response != 'object' || Array.isArray(response)) {
					console.error('Non-object part in bulk apiCall', response)
					throw new APIError(url, 'bulk_part_nonobject', 0)
				}
				if (!response) continue
				idAssert<JSONObject>(response)
				for (const [k, v] of Object.entries(response)) {
					if (!(k in result)) {
						result[k] = v
					} else {
						if (Array.isArray(v) && Array.isArray(result[k])) {
							result[k] = (result[k] as JSONArray).concat(v)
						} else if (!eq(v, result[k])) {
							throw new APIError(url, 'bulk_conflict', 0, {
								k,
								v,
								p: result[k] as JSONData,
							})
						}
					}
				}
			}
			return result
		}

		url = apiCallUrl(url, params, { type: false })

		if (!method && body === undefined) method = 'GET'

		const controller =
			timeout && 'AbortController' in window
				? new AbortController()
				: null
		let timer: number | null = null

		return runner.run(async () => {
			if (debug) console.log('apiCall', method, url, body, outBody)

			const req = await Promise.race([
				fetch(url, {
					body: outBody,
					method,
					headers,
					signal: controller?.signal ?? undefined,
				}),
				new Promise<Response>((_, ko) => {
					if (timeout) {
						timer = window.setTimeout(() => {
							ko(new APIError(url, 'api_timeout', 0, { timeout }))
							controller?.abort()
						}, timeout)
					}
				}),
			])

			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if (timer != null) window.clearTimeout(timer)

			if (raw) {
				if (req.ok) return req
				throw new APIError(url, 'raw_error', req.status, undefined, req)
			}

			const ct =
				req.headers.get('Content-Type') ?? 'application/octet-stream'
			if (ct.split(';')[0] !== 'application/json') {
				const blob = await req.blob()
				return blob
			}

			const rawRes = await req.json()
			const { ok, ...res } = rawRes ?? { ok: true }
			if (ok) {
				if (ok === true) return res
				else return { ok, ...res }
			} else {
				const code = req.status
				if (!Array.isArray(rawRes) && typeof res == 'object') {
					const { err, ...extra } = res
					throw new APIError(
						url,
						typeof err == 'string' ? err : 'unknown',
						code,
						extra,
						req,
					)
				}
				throw new APIError(url, 'unknown', code, undefined, req)
			}
		})
	} as any,
	{ augment, url: apiCallUrl, APIError },
)

function augment(
	defaultParams: ApiCallParams,
): ApiCallImpl & { defaultParams: ApiCallParams } {
	return Object.assign(
		async (url: string, params: ApiCallParams = {}) => {
			const fullParams = Object.assign({}, defaultParams, params)
			fullParams.headers = Object.assign(
				{},
				defaultParams.headers ?? {},
				params.headers ?? {},
			)
			fullParams.params = Object.assign(
				{},
				defaultParams.params ?? {},
				params.params ?? {},
			)

			return apiCall(url, fullParams as any)
		},
		{ defaultParams },
	) as any
}

Object.assign(window, { apiCall })
